import get from 'lodash/get';
import find from 'lodash/find';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import { getOriginKey } from '../api/paymentMethodsApi';

import { providerName } from '../../constants/adyen.const';

export const nameSpace = 'AdyenStoreHubLocal';

const parseOriginKey = (originKeyResponse, appDomain) => {
  const provider = find(originKeyResponse.providers, { name: providerName });

  const originKey = get(provider, `credentials.originKeys["${appDomain}"]`);

  if (!originKey) {
    logger.message(`No originKey for your domain ${appDomain}`, LOG_TYPE.warning);
    throw new Error('No originKey for your domain: ', appDomain);
  }

  return originKey;
};

export default {
  namespaced: true,
  state: {
    originKey: null,
  },
  mutations: {
    setOriginKey(state, originKey) {
      state.originKey = originKey;
    },
  },
  actions: {
    async loadOriginKey({ commit, state }, userUuid) {
      if (!state.originKey) {
        const originSource = {
          originDomain: window.location.origin,
          userUuid,
        };

        const originResponse = await getOriginKey(originSource);

        const originKey = parseOriginKey(
          originResponse,
          window.location.origin,
        );

        commit('setOriginKey', originKey);

        return originKey;
      }

      return state.originKey;
    },
  },
};
