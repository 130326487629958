import { external } from '@emobg/web-api-client';
import omit from 'lodash/omit';
import { ACTIONS } from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import { isNullValue } from '@emobg/web-utils';

const adyenVersion = 5.59;

const { paymentsProvider, paymentsCompany, paymentsUser } = external;

export const getOriginKey = originSource => external.paymentsProvider.getOriginKey(originSource);

const getIdempotencyKey = () => ({
  idempotencyKey: crypto.randomUUID(),
});

const createCombinedResponse = (response, actionUsed) => ({
  ...response.data,
  actionUsed,
  adyenVersion,
});

const modifiedBrowserInfo = browserInfo => ({
  colorDepth: Number(browserInfo.colorDepth),
  screenHeight: Number(browserInfo.screenHeight),
  screenWidth: Number(browserInfo.screenWidth),
  timeZoneOffset: Number(browserInfo.timeZoneOffset),
  userAgent: browserInfo.userAgent,
  acceptHeader: browserInfo.acceptHeader,
  language: browserInfo.language,
  javaEnabled: browserInfo.javaEnabled,
});

const commonAddCard = async (uuid, payload) => {
  const { channel, origin, browserInfo } = payload;

  const referrer = {
    channel,
    origin,
    browserInfo: modifiedBrowserInfo(browserInfo),
  };

  const paymentMethod = () => {
    const filteredPaymentMethod = omit(payload.paymentMethod, ['holderName', 'brand', 'defaultPaymentMethod']);

    return filteredPaymentMethod;
  };

  const combinedPayload = {
    referrer,
    holder: payload.paymentMethod.holderName,
    returnUrl: payload.return_url,
    ...uuid,
    ...paymentMethod(),
    ...getIdempotencyKey(),
  };

  const response = await paymentsProvider.postAddCard(combinedPayload);

  const combinedResponse = createCombinedResponse(response, ACTIONS.add);

  return combinedResponse;
};

export const addUserNewPaymentMethod = (uuid, payload) => commonAddCard({ userUuid: uuid }, payload);

export const addCompanyNewPaymentMethod = (uuid, payload) => commonAddCard({ companyUuid: uuid }, payload);

export const authenticateCardDetails = async (payload, version) => {
  const { actionUsed } = payload;

  const combinedPayload = {
    ...payload,
    ...getIdempotencyKey(),
  };

  let response = null;

  if (actionUsed === ACTIONS.add) {
    response = await paymentsProvider.postCardDetails(combinedPayload, version);
  }

  if (actionUsed === ACTIONS.payment) {
    response = await paymentsProvider.postPaymentDetails(combinedPayload, version);
  }

  if (isNullValue(response)) {
    return response;
  }

  const combinedResponse = createCombinedResponse(response, combinedPayload.actionUsed);

  return combinedResponse;
};

export const getRedirectPaymentMethod = async (payload, version) => {
  const combinedPayload = {
    ...payload,
    ...getIdempotencyKey(),
  };

  const { reference } = payload;

  const response = await paymentsProvider.getRedirectData(reference, version);

  const combinedResponse = createCombinedResponse(response, combinedPayload.isActionAdd);

  return combinedResponse;
};

export const setUserDefaultPaymentMethod = (uuid, payload) => paymentsUser.putLinkPaymentMethodToUser(uuid, payload, 'v3');

export const setCompanyDefaultPaymentMethod = (
  companyUuid, paymentMethodUuid, userUuid,
) => paymentsCompany.putSetDefaultPaymentMethod(companyUuid, paymentMethodUuid, userUuid);

export const getUserPaymentMethodsCollection = uuid => paymentsUser.getUserPaymentMethods(uuid);
export const getCompanyPaymentMethodsCollection = uuid => paymentsCompany.getCompanyPaymentMethods(uuid);

export const removeUserPaymentMethod = (userUuid, payload) => paymentsUser.putRemovePaymentMethod(userUuid, payload);
export const removeCompanyPaymentMethod = (companyUuid, payload) => paymentsCompany.putRemovePaymentMethod(companyUuid, payload);

export const redirectResult = reference => external.paymentsProvider.getRedirectResult(reference);

export const confirmRedirect = payload => external.paymentsProvider.postConfirmRedirect(payload);

export const redirectBookingPaymentResult = bookingInvoiceUuid => external.paymentsProvider.getBookingPaymentResult(bookingInvoiceUuid);

export const payInvoice = async payload => {
  const {
    channel, origin, browserInfo: providedbrowserInfo, paymentMethodUuid, invoiceUuid, resourceType, resourceUuid,
  } = payload;

  const referrer = {
    channel,
    origin,
    browserInfo: modifiedBrowserInfo(providedbrowserInfo),
  };

  const combinedPayload = {
    referrer,
    customerPaymentMethodUuid: paymentMethodUuid,
    encryptedSecurityCode: payload.paymentMethod.securityCode,
    ...getIdempotencyKey(),
    resourceType,
    resourceUuid,
    returnUrl: payload.return_url,
  };

  const response = await paymentsProvider.postPayInvoice(invoiceUuid, combinedPayload);

  const combinedResponse = createCombinedResponse(response, payload.actionUsed);

  return combinedResponse;
};

// insurances
export const createUserInsuranceInvoice = userUuid => paymentsProvider.postCreateUserInsuranceInvoice(
  userUuid,
);

// booking
export const postBookingInitPayment = (invoiceUuid, payload) => paymentsProvider.postBookingInitPayment(invoiceUuid, payload);
export const putBookingResolvePayment = (bookingUuid, payload) => paymentsProvider.putBookingResolvePayment(bookingUuid, payload);

// editBooking
export const putEditBookingPayment = (bookingUuid, payload) => paymentsUser.putEditBookingPayment(bookingUuid, payload);
